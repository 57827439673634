import React, { PureComponent } from 'react'
import history from 'helpers/history'
import {
    getUpdatedList, isValidDate, onColumnClick,
} from 'helpers/methods/common'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import flattenObj from 'helpers/methods/flattenObj'
import styles from 'styles/pages/waybills/index.module.scss'
import { tKey, tObj } from 'helpers/methods/translate'
import {
    ShimmeredDetailsList, Text, Icon, Label, DefaultButton, DirectionalHint, TooltipDelay, TooltipHost, TextField,
    SelectionMode, ConstrainMode,
    DetailsListLayoutMode,
    IconButton, Checkbox,
    PrimaryButton,
    MessageBarType,
} from '@fluentui/react'
import { Columns } from 'react-bulma-components'
import parseJson from 'helpers/methods/parseJson'
import Card from 'components/containers/card'
import Transport, { TransportFile } from 'requests/objects/transport'
import HandleBlob from 'helpers/methods/blob'
import Time from 'helpers/methods/time'
import ConfirmedDateLogo from 'assets/img/confirmed-date.png'
import ScheduledDateLogo from 'assets/img/scheduled-date.png'
import EFileFolder from 'types/files/enums/fileFolder'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'

/** @typedef {import("app").AppProps} AppProps  */

/** @debug {AppProps} */

/**
 * @typedef {object} SearchParamsType
 * @property {number} clientId clientId
 * @property {string} references references
 * @property {Date} pickupDate pickupDate
 * @property {Date} pickupDateEnd pickupDateEnd
 * @property {string} pickupAddress pickupAddress
 * @property {Date} deliveryDate deliveryDate
 * @property {Date} deliveryDateEnd deliveryDateEnd
 * @property {string} deliveryAddress pickupAddress
 */

/**
 * @type {SearchParamsType}
 */
const defaultSearchParams = {
    clientId: null,
    references: '',
    pickupDate: null,
    pickupDateEnd: null,
    pickupAddress: '',
    deliveryDate: null,
    deliveryDateEnd: null,
    deliveryAddress: '',
}

/**
 * @augments {PureComponent<AppProps>} extends
 */
export default class IndexWaybills extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {Transport[]} Items found in API */
            items: [],
            /** @type {SearchParamsType} Params to search */
            searchParams: defaultSearchParams,
            /** @type {import('@fluentui/react').IColumn[]} Columns displayed */
            columns: [
                {
                    key: 'transportTmsId',
                    name: this.tKey('transport'),
                    fieldName: 'transportTmsId',
                    minWidth: 90,
                    maxWidth: 180,
                    isResizable: true,
                    className: styles['transport-list-row-item'],
                    /**
                     * @param {Transport} transport transport
                     */
                    onRender:
                        // eslint-disable-next-line react/no-unstable-nested-components
                        transport => (
                            <div className={styles['transport-list-row-item-checked']}>
                                <Checkbox
                                    checked={transport.isSelected}
                                    onChange={ev => {
                                        ev.stopPropagation()
                                        this.onCheckBoxChange(transport.transportId)
                                    }}
                                />
                                <span>{transport.transportTmsId}</span>
                            </div>
                        ),
                },
                {
                    key: 'contractor',
                    name: this.tKey('refDo'),
                    fieldName: 'contractor',
                    minWidth: 50,
                    maxWidth: 90,
                    isResizable: true,
                    className: styles['transport-list-row-item'],
                },
                {
                    key: 'createdAt',
                    name: this.tKey('createdThe'),
                    minWidth: 50,
                    maxWidth: 75,
                    isResizable: true,
                    className: styles['transport-list-row-item'],
                    onRender:
                        // eslint-disable-next-line react/no-unstable-nested-components
                        transport => (
                            transport.createdAt
                                ? this.Time(transport.createdAt).getCleanDate({
                                    month: '2-digit',
                                    day: '2-digit',
                                    year: 'numeric',
                                })
                                : ''
                        ),
                },
                {
                    key: 'pickupDate',
                    name: this.tKey('pickupDate'),
                    minWidth: 80,
                    maxWidth: 150,
                    isResizable: true,
                    className: styles['transport-list-row-item'],
                    onRender:
                        // eslint-disable-next-line react/no-unstable-nested-components
                        transport => {
                            const date = transport.pickupDate || transport.pickupScheduledDate
                            if (!date)
                                return ''
                            return (
                                <TooltipHost
                                    content={this.tKey(transport.pickupDate ? 'picked' : 'estimated')}
                                    directionalHint={DirectionalHint.leftBottomEdge}
                                    delay={TooltipDelay.zero}
                                >
                                    <div className={styles['transport-list-row-item-date']}>
                                        {transport.pickupDate ? (
                                            <img
                                                src={ConfirmedDateLogo}
                                                alt="Date de prise en charge"
                                            />
                                        ) : (
                                            <img
                                                src={ScheduledDateLogo}
                                                alt="Date de prise en charge prévisionnelle"
                                            />
                                        )}
                                        <span>
                                            {this.Time(date).getCleanDate({
                                                month: '2-digit',
                                                day: '2-digit',
                                                year: 'numeric',
                                            })}
                                        </span>
                                    </div>
                                </TooltipHost>
                            )
                        },
                },
                {
                    key: 'pickup',
                    name: this.tKey('pickup'),
                    minWidth: 100,
                    maxWidth: 220,
                    isResizable: true,
                    className: styles['transport-list-row-item'],
                    onRender:
                        // eslint-disable-next-line react/no-unstable-nested-components
                        transport => (
                            <>
                                <b>
                                    {transport['pickupAddress.label'] || ''}
                                </b>
                                <span>
                                    {transport['pickupAddress.street'] || ''}
                                </span>
                                <span>
                                    {transport['pickupAddress.zipCode'] || ''}
                                    {' '}
                                    {transport['pickupAddress.city'] || ''}
                                    {' '}
                                    {transport['pickupAddress.country.code'] || ''}
                                </span>
                            </>
                        ),
                },
                {
                    key: 'deliveryDate',
                    name: this.tKey('deliveryDate'),
                    minWidth: 80,
                    maxWidth: 150,
                    isResizable: true,
                    className: styles['transport-list-row-item'],
                    onRender:
                        // eslint-disable-next-line react/no-unstable-nested-components
                        transport => {
                            const date = transport.deliveryDate || transport.deliveryScheduledDate
                            if (!date)
                                return ''
                            return (
                                <TooltipHost
                                    content={this.tKey(transport.deliveryDate ? 'delivered' : 'planned')}
                                    directionalHint={DirectionalHint.leftBottomEdge}
                                    delay={TooltipDelay.zero}
                                >
                                    <div className={styles['transport-list-row-item-date']}>
                                        {transport.deliveryDate ? (
                                            <img
                                                src={ConfirmedDateLogo}
                                                alt="Date de livraison"
                                            />

                                        ) : (
                                            <img
                                                src={ScheduledDateLogo}
                                                alt="Date de livraison prévisionnelle"
                                            />
                                        )}
                                        <span>
                                            {this.Time(date).getCleanDate({
                                                month: '2-digit',
                                                day: '2-digit',
                                                year: 'numeric',
                                            })}
                                        </span>
                                    </div>
                                </TooltipHost>
                            )
                        },
                },
                {
                    key: 'delivery',
                    name: this.tKey('delivery'),
                    minWidth: 100,
                    maxWidth: 220,
                    isResizable: true,
                    className: styles['transport-list-row-item'],
                    onRender:
                        // eslint-disable-next-line react/no-unstable-nested-components
                        transport => (
                            <>
                                <b>
                                    {transport['deliveryAddress.label'] || ''}
                                </b>
                                <span>
                                    {transport['deliveryAddress.street'] || ''}
                                </span>
                                <span>
                                    {transport['deliveryAddress.zipCode'] || ''}
                                    {' '}
                                    {transport['deliveryAddress.city'] || ''}
                                    {' '}
                                    {transport['deliveryAddress.country.code'] || ''}
                                </span>
                            </>
                        ),
                },
                {
                    key: 'ldv',
                    name: 'LDV',
                    minWidth: 100,
                    maxWidth: 220,
                    isResizable: true,
                    className: styles['transport-list-row-item'],
                    onRender:
                        // eslint-disable-next-line react/no-unstable-nested-components
                        transport => transport['transportFiles.0.name'],
                },
                {
                    key: 'file',
                    name: this.tKey('file'),
                    fieldName: 'file',
                    minWidth: 75,
                    maxWidth: 125,
                    isResizable: true,
                    className: styles['transport-list-row-item'],
                    onRender:
                        // eslint-disable-next-line react/no-unstable-nested-components
                        transport => (
                            <IconButton
                                iconProps={{ iconName: 'PDF' }}
                                title={this.tKey('download')}
                                onClick={ev => {
                                    ev.stopPropagation()
                                    this.downloadWaybill({
                                        fileId: transport['transportFiles.0.fileId'],
                                        name: transport['transportFiles.0.name'],
                                        transportFileId: transport['transportFiles.0.transportFileId'],
                                        url: transport['transportFiles.0.url'],
                                    })
                                }}
                            />
                        ),
                },
            ],
            /** @type {boolean} Is filter card reduced ? */
            isFilterCardReduced: true,
        }

        this.submitInput = React.createRef()
        this.toolTipRef = React.createRef()

        /** @type {NodeJS.Timeout} Time out to handle apply of searchParams */
        this.timeOutParams = null

        this.onCheckBoxChange = this.onCheckBoxChange.bind(this)
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()

        if (!window.location.search) {
            this.syncSearchParamsInHistory()
        } else {
            const queryParams = new URLSearchParams(window.location.search)

            const pickupDateTimestamp = Date.parse(queryParams.get('pickupDate'))
            const deliveryDateTimestamp = Date.parse(queryParams.get('deliveryDate'))

            // Reset Filter
            this.setState({
                /** @type {SearchParamsType} */
                searchParams: {
                    clientId: +queryParams.get('clientId') ? +queryParams.get('clientId') : null,
                    references: queryParams.get('references') ? queryParams.get('references') : '',
                    pickupAddress: queryParams.get('pickupAddress') ? queryParams.get('pickupAddress') : '',
                    deliveryAddress: queryParams.get('deliveryAddress') ? queryParams.get('references') : '',
                    pickupDate: !Number.isNaN(pickupDateTimestamp) ? new Date(pickupDateTimestamp) : null,
                    pickupDateEnd: !Number.isNaN(pickupDateTimestamp) ? new Date(pickupDateTimestamp) : null,
                    deliveryDate: !Number.isNaN(deliveryDateTimestamp) ? new Date(deliveryDateTimestamp) : null,
                    deliveryDateEnd: !Number.isNaN(deliveryDateTimestamp) ? new Date(deliveryDateTimestamp) : null,
                },
            }, () => this.syncSearchParamsInHistory())
        }
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        const { match } = this.props
        const { searchParams } = this.state

        if (JSON.stringify(prevState.searchParams) !== JSON.stringify(searchParams)) {
            // Apply changement with delay to prevent lag
            clearTimeout(this.timeOutParams)
            this.timeOutParams = setTimeout(() => {
                history.replace({ search: '' })
                this.syncSearchParamsInHistory()
            }, 250)
        }

        if (match.params?.key !== prevProps.match.params?.key)
            this.setState(truePevState => ({
                searchParams: {
                    ...truePevState.searchParams,
                    workflowTransportStepIds: [],
                },
            }), () => {
                this.init()
                this.search()
            })
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.transportsHandlerGetWaybills?.cancel()
        this.downloadWaybillHandler?.cancel()
        this.downloadWaybillsHandler?.cancel()
    }

    /**
     * onCheckBoxChange
     * @param {number} transportId transportId
     */
    onCheckBoxChange(transportId) {
        this.setState(prevState => ({
            items: prevState.items.map(transport => {
                if (transportId !== transport.transportId)
                    return transport
                return {
                    ...transport,
                    isSelected: !transport.isSelected,
                }
            }),
        }))
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props

        setBreadcrumb([
            { text: this.tKey('waybills'), key: 'order' },
            { text: this.tKey('myWaybills'), key: 'all-transport', isCurrentItem: true },
        ])
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const { setCommand } = this.props

        setCommand([])
    }

    /**
     * Sync SearchParams in navigation history
     */
    syncSearchParamsInHistory() {
        const { searchParams } = this.state

        const queryParams = new URLSearchParams(window.location.search)

        // eslint-disable-next-line no-restricted-syntax
        for (const key in searchParams)
            if (searchParams[key] !== null && searchParams[key] !== '' && searchParams[key]?.length !== 0)
                if (Array.isArray(searchParams[key]))
                    // eslint-disable-next-line no-restricted-syntax
                    for (const element of searchParams[key])
                        queryParams.append(key, element)
                else
                    queryParams.set(key, searchParams[key])
            else
                queryParams.delete(key)

        history.replace({
            search: queryParams.toString(),
        })
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        this.setupCommandBar()
    }

    /**
     * Search elements
     */
    search() {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { transportsHandler } = this.props
            const { searchParams } = this.state
            try {
                this.transportsHandlerGetWaybills?.cancel()
                this.transportsHandlerGetWaybills = transportsHandler.getWaybills(searchParams)
                const res = await this.transportsHandlerGetWaybills.fetch()
                this.setState({
                    items: res.map(x => this.flattenObj(x)),
                    status: Status.RESOLVED,
                })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * @param {Partial<TransportFile>} transportFile transportFile
     */
    async downloadWaybill(transportFile) {
        const { filesHandler } = this.props
        try {
            this.downloadWaybillHandler?.cancel()
            this.downloadWaybillHandler = transportFile.url ? filesHandler.getTransportCMRFile(transportFile.transportFileId)
                : filesHandler.getFile(EFileFolder.TransportFile, transportFile.fileId)
            const blob = await this.downloadWaybillHandler.fetch()
            HandleBlob.download(blob, transportFile.name)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError:
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }

    /**
     * Export elements
     * @param {number[]} transportFileIds transportFileIds
     */
    downloadWaybills(transportFileIds) {
        const { setMessageBar, me } = this.props
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { filesHandler } = this.props
            try {
                setMessageBar({ isDisplayed: false })
                this.downloadWaybillsHandler?.cancel()
                this.downloadWaybillsHandler = filesHandler.downloadWayBills(transportFileIds)
                const { data, fileName } = await this.downloadWaybillsHandler.fetch()

                if (fileName === 'errors.json') {
                    const textDecoder = new TextDecoder('utf-8')
                    const jsonString = textDecoder.decode(new Uint8Array(data))
                    const errors = JSON.parse(jsonString)

                    this.setState(prevState => ({
                        ...prevState,
                        items: prevState.items.filter(item => !errors.some(x => x.transportId === item.transportId)),
                    }))
                    const message = errors.map(x => this.tKey('waybillNotDownloadable', { waybill: x.name }))
                    setMessageBar({
                        isDisplayed: true,
                        type: MessageBarType.severeWarning,
                        message: me.isClient ? [...message, this.tKey('emailHasBeenGenerated')].join(' ') : message,
                    })
                } else {
                    const blob = new Blob([data])
                    HandleBlob.download(blob, fileName)
                }

                this.setState({ status: Status.RESOLVED })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError:
                    case NotImplementedError:
                    default:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        this.setState({ status: Status.REJECTED })
                        break
                }
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const { me, param: { clients } } = this.props
        const {
            status, isFilterCardReduced, items, searchParams, columns,
        } = this.state

        const selectedItems = items.filter(transport => transport.isSelected)

        return (
            <main className={styles.index}>
                <Card
                    className={styles['filters-card']}
                    title={this.tKey('filters')}
                    iconName="FilterSettings"
                    headerComponent={(
                        <button
                            type="button"
                            onClick={() => this.setState({ isFilterCardReduced: !isFilterCardReduced })}
                            className={styles['reduce-button-container']}
                        >
                            <div className={styles['reduce-button']}>
                                <Text>
                                    {isFilterCardReduced ? this.tKey('reduce') : this.tKey('extend')}
                                </Text>
                                <Text>
                                    <Icon iconName={isFilterCardReduced ? 'ChevronUp' : 'ChevronDown'} />
                                </Text>
                            </div>
                        </button>
                    )}
                >
                    {isFilterCardReduced && (
                        <form
                            onSubmit={ev => {
                                ev.preventDefault()
                                this.search()
                            }}
                        >
                            <Columns>
                                {me.isAdmin && (
                                    <Columns.Column size="one-fifth">
                                        <FilteredVirtualCombobox
                                            label={this.tKey('client')}
                                            placeholder={this.tKey('select')}
                                            options={clients}
                                            selectedKey={searchParams.clientId}
                                            disabled={status === Status.PENDING}
                                            onChange={(_ev, option) => this.setState({
                                                searchParams: { ...searchParams, clientId: option.key },
                                            })}
                                            required
                                        />
                                    </Columns.Column>
                                )}
                                <Columns.Column size="one-fifth">
                                    <TextField
                                        label={this.tKey('searchReferences')}
                                        placeholder={this.tKey('search')}
                                        disabled={status === Status.PENDING || (me.isAdmin && !searchParams.clientId)}
                                        value={searchParams.references}
                                        onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, references: newVal } })}
                                    />
                                </Columns.Column>
                            </Columns>
                            <Columns>
                                <Columns.Column size="one-fifth">
                                    <TextField
                                        label={this.tKey('pickupAddress')}
                                        placeholder={this.tKey('pickupAddress')}
                                        value={searchParams.pickupAddress}
                                        disabled={status === Status.PENDING}
                                        onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, pickupAddress: newVal } })}
                                    />
                                </Columns.Column>
                                <Columns.Column
                                    size="one-fifth"
                                    className={styles['date-filter']}
                                >
                                    <TextField
                                        label={this.tKey('pickupDate')}
                                        placeholder={this.tKey('pickupDate')}
                                        value={searchParams.pickupDate ? this.Time(searchParams.pickupDate).getLocaleDateString() : ''}
                                        onChange={(ev, newVal) => {
                                            ev.preventDefault()
                                            const date = new Date(newVal)
                                            this.setState({
                                                searchParams: {
                                                    ...searchParams,
                                                    pickupDate: this.isValidDate(date) ? date : null,
                                                },
                                            })
                                        }}
                                        type="date"
                                    />
                                    <TextField
                                        disabled={!searchParams.pickupDate}
                                        value={searchParams.pickupDateEnd ? this.Time(searchParams.pickupDateEnd).getLocaleDateString() : ''}
                                        onChange={(ev, newVal) => {
                                            ev.preventDefault()
                                            const date = new Date(newVal)
                                            this.setState({
                                                searchParams: {
                                                    ...searchParams,
                                                    pickupDateEnd: this.isValidDate(date) ? date : null,
                                                },
                                            })
                                        }}
                                        type="date"
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-fifth">
                                    <TextField
                                        label={this.tKey('deliveryAddress')}
                                        placeholder={this.tKey('deliveryAddress')}
                                        value={searchParams.deliveryAddress}
                                        disabled={status === Status.PENDING}
                                        onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, deliveryAddress: newVal } })}
                                    />
                                </Columns.Column>
                                <Columns.Column
                                    size="one-fifth"
                                    className={styles['date-filter']}
                                >
                                    <TextField
                                        label={this.tKey('deliveryDate')}
                                        placeholder={this.tKey('deliveryDate')}
                                        value={searchParams.deliveryDate ? this.Time(searchParams.deliveryDate).getLocaleDateString() : ''}
                                        onChange={(ev, newVal) => {
                                            ev.preventDefault()
                                            const date = new Date(newVal)
                                            this.setState({
                                                searchParams: {
                                                    ...searchParams,
                                                    deliveryDate: this.isValidDate(date) ? date : null,
                                                },
                                            })
                                        }}
                                        type="date"
                                    />
                                    <TextField
                                        disabled={!searchParams.deliveryDate}
                                        value={searchParams.deliveryDateEnd ? this.Time(searchParams.deliveryDateEnd).getLocaleDateString() : ''}
                                        onChange={(ev, newVal) => {
                                            ev.preventDefault()
                                            const date = new Date(newVal)
                                            this.setState({
                                                searchParams: {
                                                    ...searchParams,
                                                    deliveryDateEnd: this.isValidDate(date) ? date : null,
                                                },
                                            })
                                        }}
                                        type="date"
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-fifth">
                                    <div
                                        className={styles['action-filter-buttons']}
                                    >
                                        <div>
                                            <Label className="is-hidden-mobile">&nbsp;</Label>
                                            <DefaultButton
                                                text={this.tKey('research')}
                                                primary
                                                disabled={status === Status.PENDING || (me.isAdmin && !searchParams.clientId)}
                                                onClick={() => this.submitInput.current.click()}
                                                iconProps={{ iconName: 'Search' }}
                                            />
                                        </div>
                                        <div className={styles.separator} />
                                        <div>
                                            <Label className="is-hidden-mobile">&nbsp;</Label>
                                            <TooltipHost
                                                content={this.tKey('resetFilters')}
                                                directionalHint={DirectionalHint.topAutoEdge}
                                                delay={TooltipDelay.medium}
                                                componentRef={this.toolTipRef}
                                            >
                                                <DefaultButton
                                                    disabled={status === Status.PENDING}
                                                    styles={{
                                                        root: {
                                                            minWidth: '40px',
                                                            maxWidth: '40px',
                                                        },
                                                    }}
                                                    iconProps={{ iconName: 'ClearFilter' }}
                                                    onClick={() => this.setState({
                                                        /** @type {SearchParamsType} */
                                                        searchParams: defaultSearchParams,
                                                    })}
                                                />
                                            </TooltipHost>
                                        </div>
                                    </div>
                                </Columns.Column>
                            </Columns>

                            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                            <button
                                type="submit"
                                style={{ display: 'none' }}
                                ref={this.submitInput}
                                tabIndex={-1}
                            />
                        </form>
                    )}
                </Card>
                <br />
                <Card
                    customHeader={(
                        <div className={styles['header-list']}>
                            <span>
                                {this.tKey('nbSelected', { nb: selectedItems.length.toString() })}
                            </span>
                            <DefaultButton
                                iconProps={{ iconName: 'ArrowUpRight' }}
                                onClick={() => this.setState(prevState => ({
                                    items: prevState.items.map(transport => ({
                                        ...transport,
                                        isSelected: !selectedItems.length,
                                    })),
                                }))}
                            >
                                {
                                    selectedItems.length
                                        ? this.tKey('deselect', { nb: selectedItems.length.toString() })
                                        : this.tKey('selectAllWithNb', { nb: items.length.toString() })
                                }
                            </DefaultButton>
                            <PrimaryButton
                                disabled={!selectedItems.length || status === Status.PENDING}
                                iconProps={{ iconName: 'Download' }}
                                onClick={() => this.downloadWaybills(selectedItems.map(x => x['transportFiles.0.transportFileId']))}
                            >
                                {`${this.tKey('download')} ZIP`}
                            </PrimaryButton>
                        </div>
                    )}
                >
                    <ShimmeredDetailsList
                        items={items}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => true}
                        enableShimmer={status === Status.PENDING}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        onRenderRow={(props, defaultRender) => (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                            <div
                                role="button"
                                tabIndex={0}
                                onClick={() => this.onCheckBoxChange(props.item.transportId)}
                                className={styles['waybills-row']}
                            >
                                {defaultRender(props)}
                            </div>
                        )}
                        onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                    />
                    {
                        !items?.length && [Status.RESOLVED, Status.REJECTED].includes(status)
                        && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{this.tKey('noResultFound')}</Text>
                    }
                </Card>
            </main>
        )
    }
}

IndexWaybills.prototype.onColumnClick = onColumnClick
IndexWaybills.prototype.Time = Time
IndexWaybills.prototype.isValidDate = isValidDate
IndexWaybills.prototype.parseJson = parseJson
IndexWaybills.prototype.getUpdatedList = getUpdatedList
IndexWaybills.prototype.tKey = tKey
IndexWaybills.prototype.tObj = tObj
IndexWaybills.prototype.flattenObj = flattenObj
