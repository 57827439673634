/* eslint-disable max-classes-per-file */
import React, { PureComponent } from 'react'
import Login from 'pages/login'
import Signup from 'pages/signup'
import ResetPassword from 'pages/reset-password'
import { initializeIcons } from '@fluentui/react/lib/Icons'
import { connect } from 'react-redux'
import Layout from 'pages/_layout'
import { loadTheme, MessageBarType } from '@fluentui/react'
import { PrivateRoute, PublicRoute } from 'components/routes'
import history from 'helpers/history'
import Modal from 'components/containers/modal'
import UserPhoneModal from 'components/pages/userPhoneModal'
// import ClientLogoModal from 'components/pages/clientLogoModal'
import Index from 'pages/index'
// @ts-ignore
// eslint-disable-next-line import/named
import withHandlers, { HandlersProps } from 'helpers/hoc/withHandlers'
import PublicLayout from 'pages/_publicLayout'
import {
    RouteChildrenProps, Switch, Router, Redirect,
} from 'react-router-dom'
import IndexUsers from 'pages/users/index'
import IdUsers from 'pages/users/[id]'
import IndexClients from 'pages/clients/index'
import IdClients from 'pages/clients/[id]'
import IndexCompanies from 'pages/companies/index'
import IdCompanies from 'pages/companies/[id]'
import IndexClientAccounts from 'pages/client-accounts/index'
import IdClientAccounts from 'pages/client-accounts/[id]'
// @ts-ignore
// eslint-disable-next-line import/named
import { mapStateToProps, mapDispatchToProps, ReduxProps } from 'redux/map'
import NotFound from 'pages/notFound'
import User from 'requests/objects/user'
import Param from 'requests/objects/param'
import { NEW_PATH } from 'types/others'
import { clientProfiles, userProfilesWithSuperAdmin } from 'types/users/profiles'
import IndexHomeActualities from 'pages/home-actualities'
import IdHomeActualities from 'pages/home-actualities/[id]'
import IndexHomeArticles from 'pages/home-articles'
import IdHomeArticles from 'pages/home-articles/[id]'
import { tKey, tObj } from 'helpers/methods/translate'
import IndexOrders from 'pages/orders'
import IndexStocks from 'pages/stocks'
import IdClientOrders from 'pages/orders/[id]/client'
import IdAdminOrders from 'pages/orders/[id]/admin'
import Tracking from 'pages/tracking/[id]'
import IdTransports from 'pages/transports/[id]'
import IndexTransport from 'pages/transports'
import IndexWaybills from 'pages/waybills'
import IndexInvoices from 'pages/invoices'
import IdInvoices from 'pages/invoices/[id]'
import IndexDocuments from 'pages/documents'
import IdDocuments from 'pages/documents/[id]'
import IndexContacts from 'pages/contacts'
import IndexTools from 'pages/tools'
import LegalMentions from 'pages/legal-mentions'
import BasicLayout from 'pages/_basicLayout'
import EstimateDeliveryTime from 'pages/tools/estimate-delivery-time'
import UserGuide from 'pages/tools/user-guide'
import IndexAddresses from 'pages/addresses'
import checkVersion from 'helpers/check-version'

/**
 * Global components props
 * @typedef {ReduxProps & HandlersProps & RouteChildrenProps} AppProps
 */

initializeIcons('https://static2.sharepointonline.com/files/fabric/assets/icons/')
// https://fabricweb.z5.web.core.windows.net/pr-deploy-site/refs/heads/master/theming-designer/index.html
loadTheme({
    palette: {
        themePrimary: '#00375d',
        themeLighterAlt: '#cfe0eb',
        themeLighter: '#a7c5d9',
        themeLight: '#84abc8',
        themeTertiary: '#6494b6',
        themeSecondary: '#487da4',
        themeDarkAlt: '#306992',
        themeDark: '#1c5680',
        themeDarker: '#0c456e',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    },
    fonts: {
        tiny: { fontFamily: 'gotham' },
        xSmall: { fontFamily: 'gotham' },
        small: { fontFamily: 'gotham' },
        smallPlus: { fontFamily: 'gotham' },
        medium: { fontFamily: 'gotham' },
        mediumPlus: { fontFamily: 'gotham' },
        large: { fontFamily: 'gotham' },
        xLarge: { fontFamily: 'gotham' },
        xxLarge: { fontFamily: 'gotham' },
        superLarge: { fontFamily: 'gotham' },
        mega: { fontFamily: 'gotham' },
    },
})

const AppLayout = connect(mapStateToProps, mapDispatchToProps)(withHandlers(Layout))
const AppPublicLayout = connect(mapStateToProps, mapDispatchToProps)(withHandlers(PublicLayout))
const AppBasicLayout = connect(mapStateToProps, mapDispatchToProps)(withHandlers(BasicLayout))

const AppModal = connect(mapStateToProps, mapDispatchToProps)(Modal)
const AppUserPhoneModal = connect(mapStateToProps, mapDispatchToProps)(withHandlers(UserPhoneModal))
// const AppClientLogoModal = connect(mapStateToProps, mapDispatchToProps)(withHandlers(ClientLogoModal))

const AppLogin = connect(mapStateToProps, mapDispatchToProps)(withHandlers(Login))
const AppSignup = connect(mapStateToProps, mapDispatchToProps)(withHandlers(Signup))
const AppResetPassword = connect(mapStateToProps, mapDispatchToProps)(withHandlers(ResetPassword))
const AppIndex = connect(mapStateToProps, mapDispatchToProps)(withHandlers(Index))
const AppIndexUser = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexUsers))
const AppIdUser = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdUsers))
const AppIndexClient = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexClients))
const AppIdClient = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdClients))
const AppIndexAddress = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexAddresses))
const AppIndexCompany = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexCompanies))
const AppIdCompany = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdCompanies))
const AppIndexClientAccount = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexClientAccounts))
const AppIdClientAccount = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdClientAccounts))
const AppError = connect(mapStateToProps, mapDispatchToProps)(NotFound)

const AppIndexHomeActualities = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexHomeActualities))
const AppIdHomeActualities = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdHomeActualities))

const AppIndexHomeArticles = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexHomeArticles))
const AppIdHomeArticles = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdHomeArticles))

const AppIndexOrders = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexOrders))
const AppIndexStocks = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexStocks))
const AppIdClientOrders = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdClientOrders))
const AppIdAdminOrders = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdAdminOrders))

const AppIndexTransport = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexTransport))
const AppIdTransports = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdTransports))

const AppTracking = connect(mapStateToProps, mapDispatchToProps)(withHandlers(Tracking))
const AppLegalMentions = connect(mapStateToProps, mapDispatchToProps)(withHandlers(LegalMentions))

const AppIndexInvoice = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexInvoices))
const AppIdInvoice = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdInvoices))

const AppIndexWayBills = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexWaybills))

const AppIndexDocument = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexDocuments))
const AppIdDocument = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdDocuments))

const AppIndexContact = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexContacts))
const AppIndexTool = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexTools))
const AppEstimateDeliveryTime = connect(mapStateToProps, mapDispatchToProps)(withHandlers(EstimateDeliveryTime))
const AppUserGuide = connect(mapStateToProps, mapDispatchToProps)(withHandlers(UserGuide))

/**
 * @augments {React.PureComponent<AppProps>}
 */
class _App extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            isInit: false,
        }
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        checkVersion()

        const { isAuthenticated } = this.props

        history.replace({ ...history.location, state: null }) // Force to clean history state on each refresh

        if (isAuthenticated)
            this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous props
     */
    componentDidUpdate(prevProps) {
        const {
            isAuthenticated, lang, usersHandler, setMessageBar,
        } = this.props

        if (isAuthenticated && isAuthenticated !== prevProps.isAuthenticated)
            this.init()

        if (lang !== prevProps.lang)
            usersHandler.patchMeLanguage(lang)
                .fetch()
                .catch(err => {
                    // eslint-disable-next-line no-console
                    console.error(err)
                    setMessageBar({ isDisplayed: false })
                })
    }

    /**
     * Init app by getting constants and user infos
     */
    init() {
        const {
            setMessageBar, setCommand, setBreadcrumb, paramsHandler, usersHandler, init, signOut, me: user,
        } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])
        setBreadcrumb([])

        if (window.location.pathname.includes('/tracking/') || window.location.pathname.includes('/legal-mentions'))
            return
        this.setState({ isInit: false }, async () => {
            try {
                const lastUpdate = await paramsHandler.getLastUpdate().fetch()
                const lastUpdateLocal = (() => {
                    const lastUpdateStorage = new Date(localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_last_update`))
                    return lastUpdateStorage instanceof Date && !Number.isNaN(lastUpdateStorage.getTime()) ? lastUpdateStorage : null
                })()
                if (
                    !lastUpdateLocal
                    || lastUpdate > lastUpdateLocal
                    || !localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_me`)
                    || !localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_param`)
                    || !user.userId
                ) {
                    const [me, param] = await Promise.all([
                        usersHandler.getMe().fetch(),
                        paramsHandler.getAll().fetch(),
                    ])
                    init({
                        me, param, lastUpdate,
                    })
                    // if (me.language)
                    //     setLang(me.language)
                } else {
                    init({
                        me: new User(JSON.parse(localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_me`))),
                        param: new Param(JSON.parse(localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_param`))),
                        lastUpdate,
                    })
                    this.setState({ isInit: true })
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
                setMessageBar({
                    isDisplayed: true,
                    type: MessageBarType.error,
                    message: error,
                })
                signOut()
            } finally {
                this.setState({ isInit: true })
            }
        })
    }

    render() {
        const { isAuthenticated, me } = this.props
        const { isInit } = this.state
        const isTrackingPage = window.location.pathname.includes('/tracking/')
        const isLegalMentionsPage = window.location.pathname.includes('/legal-mentions')

        return (
            <>
                <Router history={history}>
                    <AppLayout
                        isDisplay={isAuthenticated && !isTrackingPage && !isLegalMentionsPage}
                        refresh={() => {
                            localStorage.removeItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_me`)
                            localStorage.removeItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_param`)
                            localStorage.removeItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_last_update`)
                            this.init()
                        }}
                    >
                        <Switch>
                            <PrivateRoute
                                path={`/home-articles/${NEW_PATH}`}
                                component={AppIdHomeArticles}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={`${this.tKey('homeArticle')} ${this.tKey('new')}`}
                            />
                            <PrivateRoute
                                path="/home-articles/:homeArticleId"
                                component={AppIdHomeArticles}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={props => `${this.tKey('homeArticle')} n°${props.homeArticleId}`}
                            />
                            <PrivateRoute
                                path="/home-articles"
                                component={AppIndexHomeArticles}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('homeArticles')}
                            />

                            <PrivateRoute
                                path={`/home-actualities/${NEW_PATH}`}
                                component={AppIdHomeActualities}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={`${this.tKey('homeActuality')} ${this.tKey('new')}`}
                            />
                            <PrivateRoute
                                path="/home-actualities/:homeActualityId"
                                component={AppIdHomeActualities}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={props => `${this.tKey('homeActuality')} n°${props.homeActualityId}`}
                            />
                            <PrivateRoute
                                path="/home-actualities"
                                component={AppIndexHomeActualities}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('homeActualities')}
                            />

                            <PrivateRoute
                                path={`/users/${NEW_PATH}`}
                                component={AppIdUser}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={`${this.tKey('user')} ${this.tKey('new')}`}
                            />
                            <PrivateRoute
                                path="/users/:userId"
                                component={AppIdUser}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={props => `${this.tKey('user')} n°${props.userId}`}
                            />
                            <PrivateRoute
                                path="/users"
                                component={AppIndexUser}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('users')}
                            />
                            <PrivateRoute
                                path={`/client-accounts/${NEW_PATH}`}
                                component={AppIdClientAccount}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={`${this.tKey('user')} ${this.tKey('new')}`}
                            />
                            <PrivateRoute
                                path="/client-accounts/:userId"
                                component={AppIdClientAccount}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={props => `${this.tKey('user')} n°${props.userId}`}
                            />
                            <PrivateRoute
                                path="/client-accounts"
                                component={AppIndexClientAccount}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('users')}
                            />

                            <PrivateRoute
                                path={`/clients/${NEW_PATH}`}
                                component={AppIdClient}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={`${this.tKey('client')} ${this.tKey('new')}`}
                            />
                            <PrivateRoute
                                path="/clients/:clientId"
                                component={AppIdClient}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={props => `${this.tKey('client')} n°${props.clientId}`}
                            />
                            <PrivateRoute
                                path="/clients"
                                component={AppIndexClient}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('clients')}
                            />
                            <PrivateRoute
                                path="/addresses"
                                component={AppIndexAddress}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('addresses')}
                            />
                            <PrivateRoute
                                path={`/companies/${NEW_PATH}`}
                                component={AppIdCompany}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={`${this.tKey('company')} ${this.tKey('new')}`}
                            />
                            <PrivateRoute
                                path="/companies/:companyId"
                                component={AppIdCompany}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={props => `${this.tKey('company')} n°${props.companyId}`}
                            />
                            <PrivateRoute
                                path="/companies"
                                component={AppIndexCompany}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('companies')}
                            />
                            {/* {userProfilesWithSuperAdmin.includes(me.profileId) && (
                                <PrivateRoute
                                    path={`/orders/${NEW_PATH}`}
                                    component={AppIdAdminOrders}
                                    isAuthenticated={isAuthenticated}
                                    isInit={isInit}
                                    title={`${this.tKey('order')} ${this.tKey('new')}`}
                                />
                            )} */}
                            {userProfilesWithSuperAdmin.includes(me.profileId) && (
                                <PrivateRoute
                                    path="/orders/:orderId"
                                    component={AppIdAdminOrders}
                                    isAuthenticated={isAuthenticated}
                                    isInit={isInit}
                                    title={props => `${this.tKey('order')} n°${props.orderId}`}
                                />
                            )}
                            {clientProfiles.includes(me.profileId) && (
                                <PrivateRoute
                                    path={`/orders/${NEW_PATH}`}
                                    component={AppIdClientOrders}
                                    isAuthenticated={isAuthenticated}
                                    isInit={isInit}
                                    title={`${this.tKey('order')} ${this.tKey('new')}`}
                                />
                            )}
                            {clientProfiles.includes(me.profileId) && (
                                <PrivateRoute
                                    path="/orders/:orderId"
                                    component={AppIdClientOrders}
                                    isAuthenticated={isAuthenticated}
                                    isInit={isInit}
                                    title={props => `${this.tKey('order')} n°${props.orderId}`}
                                />
                            )}
                            <PrivateRoute
                                path="/orders"
                                component={AppIndexOrders}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('orders')}
                            />
                            <PrivateRoute
                                path="/transports/:key(all|waiting|progress|completed|refused)"
                                component={AppIndexTransport}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('transports')}
                            />
                            <PrivateRoute
                                path="/transports/:transportId"
                                component={AppIdTransports}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={props => `${this.tKey('transport')} n°${props.transportId}`}
                            />

                            <Redirect
                                from="/transports"
                                to="/transports/all"
                            />

                            <PrivateRoute
                                path={`/invoices/${NEW_PATH}`}
                                component={AppIdInvoice}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={`${this.tKey('invoice')} ${this.tKey('new')}`}
                            />
                            <PrivateRoute
                                path="/invoices/:invoiceId"
                                component={AppIdInvoice}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={props => `${this.tKey('invoice')} n°${props.invoiceId}`}
                            />
                            <PrivateRoute
                                path="/invoices"
                                component={AppIndexInvoice}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('invoices')}
                            />
                            <PrivateRoute
                                path="/waybills"
                                component={AppIndexWayBills}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('waybills')}
                            />
                            <PrivateRoute
                                path={`/documents/${NEW_PATH}`}
                                component={AppIdDocument}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={`${this.tKey('document')} ${this.tKey('new')}`}
                            />
                            <PrivateRoute
                                path="/documents/:documentId"
                                component={AppIdDocument}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={props => `${this.tKey('document')} n°${props.documentId}`}
                            />
                            <PrivateRoute
                                path="/documents"
                                component={AppIndexDocument}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('documents')}
                            />
                            <PrivateRoute
                                path="/contacts"
                                component={AppIndexContact}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('contacts')}
                            />
                            <PrivateRoute
                                path="/tools/estimate-delivery-date"
                                component={AppEstimateDeliveryTime}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('estimateDeliveryDate')}
                            />
                            <PrivateRoute
                                path="/tools/user-guide"
                                component={AppUserGuide}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('userGuide')}
                            />
                            <PrivateRoute
                                path="/tools"
                                component={AppIndexTool}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('tools')}
                            />
                            <PrivateRoute
                                exact
                                path="/"
                                component={AppIndex}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('home')}
                            />
                            <PrivateRoute
                                path="/stocks"
                                component={AppIndexStocks}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title={this.tKey('stocks')}
                            />
                            {isAuthenticated && !isTrackingPage && !isLegalMentionsPage && (
                                <PublicRoute
                                    component={AppError}
                                    title="Error"
                                />
                            )}
                        </Switch>
                    </AppLayout>

                    <AppPublicLayout
                        isDisplay={!isAuthenticated && !isTrackingPage && !isLegalMentionsPage}
                    >
                        <Switch>
                            <PublicRoute
                                path="/login"
                                component={AppLogin}
                                title={this.tKey('login')}
                            />
                            <PublicRoute
                                path="/signup"
                                component={AppSignup}
                                title={this.tKey('signup')}
                            />
                            <PublicRoute
                                path="/reset-password"
                                component={AppResetPassword}
                                title={this.tKey('passwordForgotten')}
                            />
                            {!isAuthenticated && !isTrackingPage && !isLegalMentionsPage && (
                                <Redirect to="/login" />
                            )}
                        </Switch>
                    </AppPublicLayout>

                    <AppBasicLayout
                        isDisplay={isTrackingPage || isLegalMentionsPage}
                    >
                        <Switch>
                            <PublicRoute
                                path="/tracking/:orderToken"
                                component={AppTracking}
                                title={this.tKey('order')}
                            />
                            <PublicRoute
                                path="/legal-mentions"
                                component={AppLegalMentions}
                                title={this.tKey('legalMentions')}
                            />
                        </Switch>
                    </AppBasicLayout>
                </Router>
                <AppModal />
                {/* <AppClientLogoModal
                    isVisible={!me?.client?.logoId && isAuthenticated && clientProfiles.includes(me.profileId)}
                /> */}
                <AppUserPhoneModal
                    isVisible={!me?.phoneNumber && isAuthenticated && clientProfiles.includes(me.profileId)}
                />
            </>
        )
    }
}

_App.prototype.tKey = tKey
_App.prototype.tObj = tObj

const App = connect(mapStateToProps, mapDispatchToProps)(withHandlers(_App))

export default App
